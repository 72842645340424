.empty-fee-and-condition-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}

.empty-main-message {
  margin-top: 24px !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #1b4171;
}

.empty-sub-message {
  color: #1b4171;
}

.bg-main {
  width: 100%;
  padding-top: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-custom {
  text-align: center;
  color: #1b4171;
}

.topic {
  font-size: 20px !important;
  font-weight: 500;
}

.title {
  font-size: 16px !important;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pt-16 {
  padding-top: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.a-icon {
  fill: #f7fbff;
}
.b-icon,
.c-icon {
  fill: #5b8ac3;
}
.c-icon {
  font-size: 9px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.d-icon {
  fill: #e0cece;
}
.e-icon {
  fill: rgba(45, 149, 244, 0.21);
}
.f-icon {
  filter: url(#c);
}
.g-icon {
  filter: url(#a);
}

.border-default-fee-condetion {
  border: 1px solid #cccccc;
  padding: 20px;
}

.custom-list-reason-cancel {
  margin-top: 10px;
  padding-left: 20px;
}

.custom-list-display-cancel {
  margin-top: 10px;
}

.text-topic {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}

.box-display-cancel {
  background-color: #eeeeee;
  color: #222222;
  border-radius: 10px;
  padding: 2px 10px;
  margin-top: 5px;
  font-weight: 500;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.number-detail {
  font-size: 17px;
  font-weight: 500;
  color: #000000cc;
}

.price-detail {
  font-size: 18px;
  font-weight: 500;
  color: #e83428;
}

.text-detail {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  color: #8f9ba9;
}

.topic-fee-and-condition {
  font-size: 18px;
  font-weight: 500;
}

.title-fee-and-condition {
  font-size: 16px;
  font-weight: 500;
}

.title-day {
  font-size: 18px;
  font-weight: 500;
  color: #2d95f4;
}

.section-reasons-topic {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and(min-width : 768px) {
  .section-have-data {
    padding: 15px 35px 64px 35px;
  }

  .section-reasons-topic {
    width: 200px;
  }
}

@media screen and(min-width : 992px) {
  .section-reasons-topic {
    width: 300px;
  }
}

@media screen and(min-width : 1024px) {
  .section-reasons-topic {
    width: 350px;
  }
}

.w-25 {
  width: 25% !important;
}

.w-70 {
  width: 70% !important;
}

.w-100 {
  width: 100% !important;
}

.section-topic {
  min-width: 105px !important;
}

.section-topic-2 {
  min-width: 105px;
}

.text-refund-detail {
  font-size: 14px;
  font-weight: 500;
}

.text-gray-refund {
  color: #cccccc;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.custom-icon-search {
  color: #00000029;
}

.justify-content-between {
  justify-content: space-between;
}

// ------------------------  Table  ---------------------------------

.section-main-refund-credit-note {
  width: 100%;
  overflow-x: scroll;
}

.section-table-refund-credit-note thead {
  font-size: 14px;
  color: #ffffff;
  background-color: #1b4270;
}

.section-table-refund-credit-note thead tr th {
  padding: 20px;
  border-left: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.section-table-refund-credit-note thead tr th:last-child {
  border-right: 1px solid #cccccc;
}

.section-table-refund-credit-note tbody tr td {
  padding: 5px 10px;
  text-align: center;
  border-left: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}

.section-table-refund-credit-note tbody tr:first-child td {
  border-top: none;
}

.section-table-refund-credit-note tbody tr td:last-child {
  border-right: 1px solid #cccccc;
}

.section-table-refund-credit-note tbody tr:last-child td {
  border-bottom: 1px solid #cccccc;
}

.custom-detail-credit-note-thead {
  text-align: left;
  .text {
    color: #ffffff;
  }
  .dot-refund {
    padding-left: 3px;
    color: red;
  }
}

.section-custom-text-all {
  font-weight: 700;
  span {
    font-size: 16px;
  }

  .bath-text {
    font-size: 13px;
    padding-left: 3px;
  }
}

// ------------------------  End Table  ---------------------------------

.button-refund-custom-add-more {
  width: 130px;
}

.text-status-summary {
  color: #2c91fc;
}

.data-details-custom {
  width: 350px;
  text-align: left !important;
}

.section-job-number {
  position: relative;
}

.suggest-section-under-job {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid #cccccc;
  overflow-y: scroll;
  max-height: 300px;
  z-index: 1;
}

.arrow-next-carlendar-refund {
  color: #2d95f4;
  height: 24px;
  padding-top: 5px;
}

.inside-suggest-job {
  padding: 15px;
  text-align: left;
}

.inside-suggest-job:hover {
  background-color: #2c91fc;
  color: #ffffff;
}

.section-summary-credit-note-custom-1 {
  display: inline-block;
  width: 70%;
}

.section-summary-credit-note-custom-2 {
  margin-top: 10px;
}

.input-edit-refund {
  padding: 10px 20px;
  outline: none;
  border: 1px solid #3891ff60;
  width: 100%;
  padding-left: 50px;
}

.custom-main-input-and-text-bath-refund input[type='text'] {
  font-size: 14px;
  font-weight: 700;
}

.custom-main-input-and-text-bath-refund {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.section-custom-bath-text-input {
  position: absolute;
  right: 5px;
}

.select-specific-input-refund {
  padding: 20px;
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
}

.refund-input-1 {
  text-align: center;
  outline: none;
  border: none;
  background-color: transparent;
}

.position-relative {
  position: relative;
}

.calendar-input-refund-detail-icon {
  position: absolute;
  right: 15px;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.d-none {
  display: none;
}

.bg-gray-input {
  background-color: #eeeeee;
}

.section-btn-and-text-sum {
  padding: 30px 0px;
  border-bottom: 1px solid #cccccc;
}

.text-summary-credit-note {
  font-size: 24px;
  font-weight: 700;
  text-align: right;
}

.btn-cal-refund-credit-note {
  background-color: #1b4170;
  padding: 15px;
  font-weight: 700;
  border-radius: 3px;
}

.btn-cal-refund-credit-note:hover {
  background-color: rgb(18, 45, 78);
}

.btn-close-cal-refund {
  padding: 15px;
  font-weight: 700;
  border-radius: 3px;
  background-color: #e7e7e7;
  cursor: not-allowed;

  .text-calculate-refund-credit-note {
    color: rgba(0, 0, 0, 0.87);
  }
}

.text-calculate-refund-credit-note {
  color: #ffffff;
  padding-left: 5px;
}

.custom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
}

@media screen and(min-width: 425px) {
  .section-summary-credit-note-custom-2 {
    margin-top: unset;
    display: inline-block;
    width: 40%;
  }

  .section-summary-credit-note-custom-1 {
    width: 60%;
  }
}

@media screen and(min-width: 768px) {
  .position-calenda-refund-detail {
    width: 372px;
    position: absolute;
    z-index: 10;
  }

  .d-md-flex {
    display: flex;
  }
}

@media screen and(min-width: 1024px) {
  .section-summary-credit-note-custom-2 {
    width: 30%;
  }

  .section-summary-credit-note-custom-1 {
    width: 70%;
  }

  .custom-main-input-and-text-bath-refund {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 50px;
  }

  .section-custom-bath-text-input {
    position: absolute;
    right: 5px;
  }

  .position-calenda-refund-detail {
    width: 372px;
    position: absolute;
    right: -24rem;
    z-index: 10;
    top: -2rem;
  }
}

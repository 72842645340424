.attribute-label {
  margin-bottom: 1rem;

  .label-title {
    font-size: 0.9em;
    font-weight: normal;
    color: #7a7a7a;
    margin-bottom: 0;
  }
}

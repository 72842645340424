.not-found {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);

  img {
    width: 107px;
  }

  h1 {
    margin: 0;
    color: #2d95f4;
    font-size: 5em;
    line-height: 70px;
  }

  h3 {
    font-size: 2em;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 1.3em;
    margin: 0;
  }
}

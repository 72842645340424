.cancelJob-error-message {
  color: red;
  font-weight: 16px;
  font-weight: 500;
}

.topic-success-modal {
  font-size: 18px;
  color: #1b4171;
  font-weight: 700;
  padding-left: 25px;
}

.cancelJob-label {
  font-weight: 16px;
  font-weight: 500;
}

.cancelJob-sub-label {
  font-size: 14px;
}

.cancelJob-number {
  font-size: 20px;
  font-weight: 500;
}

.cancelJob-detail-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}

.cancelJob-detail-value {
  font-size: 16px;
  font-weight: 500;
  color: #2d95f4;
}

.cancelJob-detail-container {
  display: flex;
  align-items: center;
}

.cancelJob-section-card {
  padding: 40px;
  background-color: white;
  margin-bottom: 7px;
}

.cancelJob-confirm-container {
  display: flex;
  justify-content: flex-end;
}

.my-custom-class-for-label {
  width: 50px; height: 20px;
  
  border: 1px solid #eb3a44;
  border-radius: 5px;
  
  background: #fee1d7;
  color: #eb3a44;
  text-align: center;
  line-height: 20px;
  
  font-weight: bold;
  font-size: 14px;
}

.job-state-confirmation-modal {
  .MuiDialogContent-root {
    p.MuiTypography-root {
      text-align: center;
      font-size: 18px;
    }
  }
}

.job-state-alert {
  .MuiDialog-container {
    .MuiDialog-paperWidthSm {
      max-width: 478px;
    }
  }
}

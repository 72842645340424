.dropzone_container {
  display: flex;
  flex-wrap: wrap;

  .dropzone {
    height: 90px;
    width: 90px;
    background-color: #f0f3f5;
    position: relative;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: darken(#f0f3f5, 2%);
    }

    &:focus {
      outline: none;
    }

    .dropzone__placeholder-content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .dropzone__icon-add {
        color: #9e9e9e;
      }
    }
  }

  .dropzone--multi--fullwidth {
    width: 100%;
  }

  .dropzone--single--hidden {
    display: none;
  }

  .thumbnail-multi {
    margin: 0 12px 12px 0;
    height: 90px;
    width: 90px;
    max-height: 90px;
    position: relative;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    }
     
    .thumbnail-multi__img {
      height: 100%;
      width: 90px;
      border-radius: 5px;
    }

    .thumbnail-multi__button-remove {
      position: absolute;
      right: -8px;
      top: -8px;
      padding: 4px;
      background-color: rgba(175, 175, 175, 0.5);
      z-index: 2;

      &:hover {
        background-color: rgba(140, 140, 140, 0.45);
      }

      .thumbnail-multi__icon-remove {
        font-size: 14px;
      }
    }
  }

  .thumbnail-single {
    position: relative;
    
    .thumbnail-single_outer {
      position: relative;
      overflow: hidden;
      height: 0;
      background-color: #f0f3f5;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
      }
  
      .thumbnail-single_inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
  
        .thumbnail-single_flexbox-centering {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
  
          .thumbnail-single__img {
            max-height: 100%;
            max-width: 100%;
            border-radius: 5px;
          }
        }
      }
    }

    .thumbnail-single__button-remove {
      position: absolute;
      right: -8px;
      top: -8px;
      padding: 4px;
      background-color: rgba(175, 175, 175, 0.5);
      z-index: 2;

      &:hover {
        background-color: rgba(140, 140, 140, 0.45);
      }

      .thumbnail-single__icon-remove {
        font-size: 14px;
      }
    }
  }
}

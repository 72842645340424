.MuiGrid-root.customer-details-content {
  padding: 14px;

  .avatar {
    width: 180px;
    height: 180px;
    margin-top: 14px;
    display: block;
  }

  > div.row:first-child {
    margin-bottom: 7px;
  }

  > div.row:nth-child(4) {
    margin-bottom: 14px;
  }

  > div.row {
    padding: 0 14px;

    > div.column {
      padding: 0;
    }
  }
}

.form-field {
  margin-bottom: 10px;

  input {
    font-size: 16px;
  }

  label.required::after {
    content: ' *';
    color: red;
  }

  .field-hint {
    color: #aaa;
    margin-top: 0.5rem;
  }
}

.from-field-fluid {
  width: 100%;

  input {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .form-field {
    input {
      font-size: 14px;
    }
  }
}

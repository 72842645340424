.pointer {
  cursor: pointer;
}

.pt-5 {
  padding-top: 2.5rem;
}

.section-status-custom {
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
}

.custom-main {
  text-align: center;
  font-weight: 400;
  color: #222222;
}

.select-active {
  border-bottom: 5px solid #1b4170;
  color: #1b4170;
  font-weight: 500;
}

.reschedule {
  margin-left: 20px;
}

.custom-main-form {
  position: relative;
  padding-top: 16px;
  background: #f7fbff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.section-show-status {
  padding-bottom: 30px;
  position: relative;
}

.section-show-status label {
  font-size: 24px !important;
  font-weight: 500;
  color: #222222;
}

.active-disable {
  width: 100%;
  height: 100%;
  background-color: #b9b8b859;
  position: absolute;
  z-index: 11;
  cursor: not-allowed;
}

.main-section-form .MuiCardContent-root {
  padding: 0px;
}

.custom-service-padding {
  padding: 16px;
}

.custom-service-padding .MuiCardContent-root {
  padding: 16px;
}

.custom-service-padding .MuiGrid-root {
  padding-bottom: 15px;
}

.custom-margin .MuiCardContent-root,
.form-field {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.section-button-cancel {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-left: 16px;
  padding-right: 16px;
}

.custom-label label {
  font-size: 32px !important;
}

.custom-scroll-cancellations {
  overflow-x: scroll;
  padding-bottom: 20px;
}

.box-section-time-and-update {
  display: flex;
  align-items: center;
  justify-content: end;
}

.show-time-sction-success {
  text-align: right;
  font-size: 16px !important;
  color: #2d95f4;
  font-weight: 500;
  padding-right: 15px;
}

.show-update-sction {
  text-align: right;
  font-size: 16px !important;
  color: #2d95f4;
  font-weight: 500;
  padding-right: 15px;
}

@media screen and (min-width: 768px) {
  .custom-main {
    font-size: 16px;
  }

  .custom-scroll-cancellations {
    padding-bottom: unset;
  }
}

@media screen and (min-width: 1024px) {
  .show-time-sction-success {
    bottom: 15px;
  }
}

// ---------- Input Field ----------

.input-field {
  width: 100%;
  padding: 20px 10px;
  border: 2px solid #7070707c;
  border-radius: 11px;
  opacity: 1;
  outline: none;
}

// ---------- Errors Message ----------

.validate-message {
  margin-top: 5px;
  min-height: 25px;
}

.errros-message-text {
  color: red;
}

// ---------- Custom Field ----------

.custom-box p {
  font-size: 20px;
}

.topic-custom {
  padding-top: 64px;
  display: flex;
  align-items: center;
}

.topic-custom p {
  margin-right: 5px;
}

.border-default {
  border-bottom: 1px solid #000000;
  box-shadow: 0px 3px 6px #00000029;
}

.active {
  background-color: #a8a8a8;
  border-bottom: 7px solid #000000;
}

.border-default:hover {
  background-color: #a8a8a8;
  border-bottom: 7px solid #000000;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .input-field {
    width: 628px;
  }
}

.box-signature-canvas-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(65, 131, 196, 1);
  height: 100px;
  background-color: #2d95f436;
  cursor: pointer;
}

.box-icon {
  svg {
    width: 100%;
    padding-right: 10px;
  }
}

.hr-custom {
  border-top: 1px solid #1b4270;
}

.title-canvas {
  font-size: 16px;
  color: #707070;
}

.custom-text-author {
  font-size: 14px;
  font-weight: 500;
}

// --------------- Modal ----------------

.section-head-modal-signature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #7e7e7e59;
  padding: 5px 15px !important;
}

.custom-padding-modal {
  padding: 15px;
}

.section-signature-modal .MuiDialogContent-root {
  padding: 0px !important;
  width: 560px;
}

.btn-default-modal-signature {
  border-radius: 4px;
  opacity: 1;
  width: 120px;
  height: 40px;
  font-weight: 500;
  color: #ffffff;
  margin-right: 15px;
}

.btn-choose-modal {
  background-color: rgba(204, 204, 204, 1);
}

.btn-draw-modal {
  background-color: rgba(27, 65, 113, 1);
}

.border-draw-choose {
  border: 2px dashed rgba(65, 131, 196, 1);
  border-radius: 5px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.box-image-custom-signature {
  width: 100%;
  height: 150px;
  padding: 0px 15px 15px 15px;

  img {
    object-fit: cover;
  }
}

.signature-success {
  width: 100%;
  height: 150px;

  img {
    border: 1px dashed rgba(65, 131, 196, 1);
    background-color: #2d95f436;
  }
}

.slot-times-container {
  > .header {
    padding: 12px 16px;
    margin-left: -16px;
    font-size: 22px;
    font-weight: 500;
    background-color: #eeeeee;
  }

  input {
    color: #47525d;
  }

  > .slot-times-topic {
    margin-bottom: 10px;
  }
}

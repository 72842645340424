.MuiGrid-root.details-content.MuiGrid-container {
  padding: 14px;

  .avatar {
    width: 180px;
    height: 180px;
    margin-top: 14px;
    display: block;
  }

  .org-container {
    display: flex;

    .avatar-org {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }

  > div.row:first-child {
    margin-bottom: 7px;
  }

  > div.row:nth-child(4) {
    margin-bottom: 14px;
  }

  > div.row {
    padding: 0 14px;

    > div.column {
      padding: 0;

      .badge {
        width: 20px;
        height: auto;
        margin-right: 7px;
      }
    }
  }
}

.ui.list.content-list { 
  padding: 8px !important;
}
.jobs-calendar {
  .DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2 {
    width: 100% !important;
    z-index: 0;

    div {
      width: 100% !important;

      .CalendarMonthGrid__horizontal {
        left: 0 !important;
      }

      .DayPicker_weekHeaders__horizontal {
        margin-left: 0 !important;
      }

      .DayPicker_weekHeader_ul {
        margin: 0;
        padding: 5px 0;
      }

      .CalendarMonth_caption {
        padding-bottom: 48px;
      }

      table.CalendarMonth_table.CalendarMonth_table_1 {
        width: 100%;
      }

      li.DayPicker_weekHeader_li.DayPicker_weekHeader_li_1 {
        width: calc(100% / 7) !important;
      }

      .DayPicker_weekHeader.DayPicker_weekHeader_1 {
        top: 58px !important;
      }

      .DayPickerNavigation_rightButton__horizontalDefault {
        right: 13px;
        width: 40px !important;
      }

      .DayPickerNavigation_leftButton__horizontalDefault {
        left: 13px;
        width: 40px !important;
      }

      .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        background: #5b8ac3;
        border: 1px solid #d4d4d4;
        color: white !important;
      }
    }
  }
}

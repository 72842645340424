@import '~normalize.css/normalize';
@import '~react-dates/lib/css/_datepicker.css';
@import '~react-datetime/css/react-datetime.css';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

a {
  color: #4183c4;
  text-decoration: none;
}

label,
legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600;
}

textarea {
  resize: vertical;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  min-height: 100px;
  padding: 10px 14px;

  &:focus {
    border-color: #85b7d9;
    outline: none;
  }
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  z-index: 11000;
  background: #cacaca;
}

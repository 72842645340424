.assign-provider {
  padding: 24px !important;
  text-align: left;

  > .condition {
    display: flex;
    align-items: center;

    svg {
      color: green;
      margin-right: 10px;
    }
  }

  > form {
    margin-top: 12px;
  }
}

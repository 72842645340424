.form-money-range-field {
  margin-bottom: 21px;
}

.money-range-input-field {
  margin: 4px !important;
}

.money-range-input-field-select-input {
  width: 100px;
  padding: 4px 0 0 0 !important;
}
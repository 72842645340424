.bg-white {
  background-color: #fff;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.section-modal-show-invoice {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
}

.section-topic-modal-invoice {
  background-color: rgba(120, 166, 221, 0.4);
  padding: 30px;
  padding-bottom: 50px;

  .section-text-and-title {
    padding-left: 130px;
    padding-right: 130px;
  }

  .topic-invoice {
    font-size: 24px;
    font-weight: 500;
  }
  .title-invoice {
    font-size: 16px;
    font-weight: 400;
  }
}

.section-table-filter-invoice {
  overflow-y: scroll;
  height: 500px;
  margin-top: 7px;
  padding-left: 160px;
  padding-right: 160px;
}

.bg-gray-invoice {
  padding-top: 30px;
  background-color: rgba(219, 219, 219, 0.4);
}

.btn-filter-invoice {
  width: 175px;
  height: 49px;
  color: #1b4170 !important;
  border: 1px solid rgba(27, 65, 112, 0.5) !important;
}

// -------------- Table --------------

.section-table-modal-invoice {
  width: 100%;
  overflow-y: scroll;

  thead {
    text-align: left;

    tr th {
      padding: 20px;
      border-top: 1px solid rgba(126, 126, 126, 0.35);
      border-bottom: 1px solid rgba(126, 126, 126, 0.35);
    }
  }

  tbody {
    tr td {
      padding: 20px;
      border-bottom: 1px solid rgba(126, 126, 126, 0.35);
    }
    tr:hover {
      background-color: #f0f0f0;
    }
  }

  .text-color-click {
    color: #5b8ac3;
  }

  .total {
    color: #222222;
    font-weight: 500;
  }

  .box-state {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 75px;
    border: 1px solid transparent;
    color: #ffffff;
    border-radius: 6px;
  }

  .status-overdue,
  .status-expired,
  .status-voided {
    background-color: #dbdbdb;
  }

  .status-cancelled {
    background-color: #e83528;
  }

  .status-pending,
  .status-suspened {
    background-color: #f1701b;
  }
  .status-paid {
    background-color: #21ba45;
  }

  .status-expected {
    background-color: #2d95f4;
  }

  .status-pending_approval {
    background-color: #ffcb2b;
  }

  .status-banned,
  .status-failed {
    background-color: #f44336;
  }

  .status-pending_renewal {
    background-color: #fbd63b;
  }
}

.section-modal-invoice-refund {
  position: fixed;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inside-modal-invoice-refund {
  padding: 20px;
  width: 700px;
  height: 500px;
  background-color: #ffffff;
}

.text-color-btn-blue:hover {
  color: #1b4170 !important;
}

.apply {
  color: #1b4170 !important;
}

.stop-scroll {
  overflow-y: hidden;
}

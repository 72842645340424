.sign-in-background {
  height: 100%;
  background: linear-gradient(270deg, #466B95 0%, #193F71 100%);

  .sign-in-container {
    display: flex;

    .details-container {
      flex: 1 1 0;
      background-color: white;
      height: 450px;
      padding: 100px;
      max-width: 600px;
      margin-top: 50px;
      text-align: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      .logo-image {
        margin-bottom: 2rem;
        height: 150px;
        width: auto;
      }
    }

    .form-container {
      flex: 1 1 0;
      background-color: white;
      box-shadow: 2px 2px 15px 0 #193F71;
      height: 550px;
      padding: 36px 80px;

      .separator {
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      .separator::before, .separator::after {
          content: '';
          flex: 1;
          border-bottom: 1px solid #000;
          opacity: 0.2;
      }
      
      .separator::before {
          margin-right: 1rem;
      }

      .separator::after {
          margin-left: 1rem;
      }

      .tenant-image {
        height: 80px;
        display: block;
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }

      .ui.section.divider {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      h3 {
        margin: 18px 0;
      }

      .form-group {
        button {
          margin-top: 30px;
        }
      }

      @media screen and (max-width: 768px) {
        padding: 36px 20px;
      }
    }
  }
}

.section-select-choice {
  font-size: 15px;
  font-weight: 400;
}

.input-select-custom {
  position: relative;
  width: 152px;
  height: 32px;
  border: 1px solid #cccccc;
  outline: none;
  background-color: #ffffff;
}

.show-menu {
  z-index: 5;
  position: absolute;
}

.font-fee {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.input-select-set-fee {
  padding-left: 10px;
  position: absolute;
  width: 75%;
  height: 100%;
  outline: none;
  border: 1px solid transparent;
}

.input-select-set-fee::placeholder {
  font-size: 15px;
  font-weight: 500;
  color: #808080;
}

.show-icon-percen-bath {
  width: 65%;
}

.show-select-custom {
  height: 32px;
  position: absolute;
  display: flex;
  align-items: center;
  right: 25%;
  font-size: 13px;
  color: #808080;
}

.icon {
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.animation-open {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.animation-close {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.choice-custom-fee {
  width: 152px;
  height: 32px;
  background-color: #b4d7f7;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 25px;
  align-items: center;
  color: #ffffff;
}

.choice-custom-fee:hover {
  background-color: #2d95f4;
  color: #ffffff;
}

.custom-bottom {
  padding-bottom: 20px;
}

.section-choices-custom .MuiCardHeader-root {
  width: 100%;
  padding: 0px;
}

.section-choices-custom {
  padding: 16px;
  padding-right: 24px;
}

.icon-delete-choice {
  padding-left: 10px;
}

.section-btn-and-view-more {
  display: flex;
  justify-content: space-between;
}

.text-view-custom {
  color: #2d95f4;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
}

@media screen and(min-width: 768px) {
  .custom-bottom {
    padding-bottom: unset;
  }
}

.modal {
  padding: 14px;

  .header > .icon.close {
    float: right;
    cursor: pointer;

    > &::before {
      padding: 10px;
    }
  }
}

.CalendarDay__highlighted_calendar {
  background-color: #4ea799 !important;
  color: #fff !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background-color: #fff;
  color: #484848 !important;
  border: 1px solid #ddd !important;
}

.btn-time-normal-job {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #2d95f436;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #1b4171;
}

.btn-active-normal-job {
  color: #ffffff;
  background-color: #2d95f4;
}

.disable-btn-normal-job {
  cursor: not-allowed;
  color: #8f9ba9;
  background-color: #cccccc;
}

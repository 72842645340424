.slot-time-container {
  button {
    word-break: break-word;
    width: 100%;
    padding: 13px 0px;
    text-align: center;
    border: 2px solid #47525d;
    border-radius: 5px;
    color: #47525d;
    cursor: pointer;
    font-weight: 500;
    height: 70px;
    outline: none;
    background-color: #fff;

    p {
      color: #d0021b;

      &.available {
        color: #2d95f4;
      }
    }

    &:not(.active):disabled {
      color: #aaaaaa;
      border-color: #e9e9e9;
      background-color: #e9e9e9;
      p {
        color: #aaaaaa;
      }
    }

    &:disabled {
      cursor: default;
    }

    &.active,
    &:not(:disabled):hover {
      background-color: #47525d;
      color: #fff;
      p,
      p.available {
        color: #fff;
      }
    }
  }
}

.gender-tag {
  display: inline-block;
  padding: 1px 8px;
  border-radius: 4px;
  color: white;
  background-color: #c8c8c8;
  text-align: center;

  &.male {
    background-color: #89cff0;
  }

  &.female {
    background-color: #f4c2c2;
  }
}

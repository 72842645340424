.timeline {
  width: 100% !important;
  padding: 0 !important;

  > div {
    padding-left: 0 !important;
    margin: 0 0 10px 0 !important;
    
    &:nth-last-child(2) {
      margin: 0 !important;
    }

    &:not(:first-child) > div:nth-child(2) > div:nth-child(2) {
      padding: 0 !important;

      :hover {
        cursor: pointer;
        background-color: #e6ecf2;
      }
    }
  }
}

.event-header {
  display: grid;
  grid-template-columns: 1fr 14.5fr 0.5fr;
}

.actor-avatar {
  justify-self: center;
  align-self: center;
  margin-right: 5px;
  margin: 10px 7px 10px 10px;
}

.event-description {
  align-self: center;
  margin: 10px 0;
}

.event-title {
  font-weight: bold;
  font-size: 1.3em;
}

.expand-icon {
  justify-self: right;
}

.ui.circular.label {
  margin-top: 7px;
  background-color: #f0f0f0;
}

.attributes {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.attribute-differences {
  padding: 3px 0 3px 6px;
  
  > span:first-child {
    background-color: #ffd3d3;
  }
  
  > span:nth-child(2) {
    background-color: #dbffd8;
  }
}

.client-details {
  display: flex;

  > i {
    position: relative;
    top: 4px;
  }
}

@media only screen and (min-width: 768px) {
  .timeline > div {
    &:first-child {
      display: flex !important;
    }

    &:not(:first-child) {
      padding-left: 45px !important;

      > div:first-child {
        display: flex !important;
        width: 20px !important;
        height: 20px !important;
        top: 1.5rem !important;
        left: 6px !important;
      }
    }
  }
}

.selected-date {
  background-color: #5b8ac3 !important;
  border: 1px solid #d4d4d4;
  color: white !important;
  font-weight: 700;
}

.hightlighted-dot {
  content: ' ';
  width: 6px;
  height: 6px;
  background-color: #eaa844;
  border-radius: 50%;

  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
}

.date-picker {
  .DayPicker.DayPicker_1.DayPicker__horizontal.DayPicker__horizontal_2.DayPicker__withBorder.DayPicker__withBorder_3 {
    width: 100% !important;

    div {
      width: 100% !important;

      .CalendarMonthGrid__horizontal {
        left: 0 !important;
      }

      .DayPicker_weekHeaders__horizontal {
        margin-left: 0 !important;
      }

      .DayPicker_weekHeader_ul {
        margin: 0;
        padding: 5px 0;
      }

      .CalendarMonth_caption {
        padding-bottom: 48px;
      }

      table.CalendarMonth_table.CalendarMonth_table_1 {
        width: 100%;
      }

      li.DayPicker_weekHeader_li.DayPicker_weekHeader_li_1 {
        width: calc(100% / 7) !important;
      }

      .DayPicker_weekHeader.DayPicker_weekHeader_1 {
        top: 58px !important;
      }

      .DayPickerNavigation_rightButton__horizontalDefault {
        right: 13px;
        width: 40px !important;
      }

      .DayPickerNavigation_leftButton__horizontalDefault {
        left: 13px;
        width: 40px !important;
      }

      .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        @extend .selected-date;
      }

      .CalendarDay__highlighted_calendar {
        background-color: #fff !important;
        color: #484848 !important;
        position: relative;

        &:hover {
          background-color: #e4e7e7 !important;
        }

        &::after {
          @extend .hightlighted-dot;
        }
      }

      .CalendarDay__highlighted_calendar.CalendarDay__selected,
      .CalendarDay__highlighted_calendar.CalendarDay__selected:hover,
      .CalendarDay__highlighted_calendar.CalendarDay__selected:active {
        @extend .selected-date;
        position: relative;

        &::after {
          @extend .hightlighted-dot;
        }
      }

      .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:hover {
        background-color: #fff !important;
        border: 1px solid #e4e7e7;
        color: #cacccd !important;
      }
    }
  }

  .legend-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #eaa844;
    border-radius: 50%;
  }

  .legend-text {
    display: inline-block;
    color: #616161;
    margin: 8px;
  }
}

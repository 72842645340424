.RichTextEditor__root___2QXK- {
  line-height: 1em;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #e0e0e0;
  border-radius: 7px;

  &:focus-within {
    background-color: #fff;
    border: 1px solid #5b8ac3;
  }

  .EditorToolbar__root___3_Aqz {
    .ButtonGroup__root___3lEAn {
      .ButtonWrap__root___1EO_R {
        > button.IconButton__root___3tqZW.Button__root___1gz0c {
          border: none;
        }
      }
    }
  }

  .RichTextEditor__editor___1QqIU {
    height: 292px;

    a {
      color: #2d95f4;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

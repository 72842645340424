.container-calendar-provider {
  width: 700px;
  margin: 0 auto;
  background-color: #ffffff;

  @media screen and (max-width: 768px) {
    width: 300px;
    padding-bottom: 100px;
    background: none;
  }

  .Calendar__weekDay {
    color: rgba(0, 0, 0, 0.8);
  }

  .Calendar__day.-ltr.-today {
    color: rgba(0, 0, 0, 0.8) !important;
    font-weight: 700 !important;
  }
  
  .Calendar__day.-ltr.-selected {
    border-radius: 50%;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.8);
  }
  
  .content-calendar-page {
    p, h5 {
      padding: 0 16px;
    }
  }

  .Calendar__footer {
    padding-bottom: 20px;
  }
  
  .Calendar {
    width: 100%;
    padding-top: 0;
    margin: 0 auto;
    z-index: 0;
  }
  
  .Calendar__weekDays {
    padding: 0;
  }
  
  .Calendar__header {
    margin-bottom: 8px;
  }
  
  .Calendar__monthText {
    color: #5B8AC3;
  }
  
  .Calendar__yearText {
    color: #5B8AC3;
  }
  
  .Calendar__monthArrow {
    color: #5B8AC3;
  }
  
  .Calendar__section.-shown {
    padding: 0;
  }
  
  .Calendar__section.-hiddenNext {
    margin-left: 26px;
  }
  
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 0;
  }
  
  .Calendar__monthArrowWrapper {
    z-index: 0;
  }

  .group_available_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    background-image: url('/Dot-mix.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .group_available_active_disable_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-mix.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .pair_available_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    background-image: url('/Dot-blue-green.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .pair_available_active_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-blue-green.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .pair_available_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
    background-image: url('/Dot-red-green.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .pair_available_disable_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-red-green.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .pair_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    background-image: url('/Dot-blue-red.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .pair_active_disable_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-blue-red.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
    background-image: url('/Dot-blue.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .active_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-blue.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .available_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
    background-image: url('/Dot-green.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .available_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-green.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
    background-image: url('/Dot-red.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .disable_day.-selected {
    border: 1px solid #1B4170;
    border-radius: 50%;
    background-image: url('/Dot-red.png') !important;
    background-size: contain;
    background-position: 40% 100%;
  }

  .container-action-calendar {
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    margin-left: 50px;

    @media screen and (max-width: 768px) {
      margin-left: -80px;
    }
  }

  .MuiButton-containedSizeLarge {
    width: 100%;
  }

  .Calendar__day.-ltr {
    height: 33px!important;
    width: 33px!important;
    margin: 3px 35px;
    min-height: 33px!important;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      margin: 3px 11px;
      height: 25px !important;
      width: 25px !important;
      min-height: 25px !important;
      font-size: 12px;
    }
  }

  .Calendar__sectionWrapper {
    min-height: 250px;

    @media screen and (max-width: 768px) {
      min-height: 190px;
    }
  }

  .container-footer-calendar {
    padding: 0 30px 10px 30px;
  }

  .text-footer {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 23px;
    padding: 5px 0 5px 20px;
  }

  .container-text-footer {
    display: flex;
    align-items: center;
    width: 50%;
    float: left;
  }

  .active-icon,
  .disable-icon,
  .available-icon {
    width: 16px;
    height: 16px;
    background-color: #2D95F4;
    border-radius: 50%;
  }

  .disable-icon {
    background-color: #F05F60;
  }

  .available-icon {
    background-color: #18cb88;
  }

  .container-calendar-provider .Calendar__day.-ltr.-selected {
    background-color: #ffffff !important;
  }

  .container-assign-provider-calendar {
    width: 100%;
    margin: 0 auto;

    .Calendar__day.-ltr {
      margin: 8px 30px;
    }

    .Calendar__sectionWrapper {
      min-height: 300px;
    }
  }
}

.card-container {
  display: grid;
  grid-template-columns: 45% 25% 25% 5%;
  grid-template-rows: auto auto auto 20px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 1em 1em;
  border-radius: .28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 1rem;
  
  .main-content {
    grid-column: 1 / span 5;
    grid-row: 1 / 2;
    margin-bottom: 14px;
    
    > a > img {
      border-radius: 50%;
      height: 60px;
      width: 60px;
      margin-right: 10px;
      vertical-align: middle;
    }
    
    > span {
      display: inline-block;
      position: absolute;
      padding-top: 14px;
      
      .title {
        font-size: 1.4rem;
      }
    }
  }
  
  .primary-support-content {
    grid-column: 1 / span 5;
    grid-row: 2 / 3;
    margin-bottom: 14px;
  }
  
  .secondary-support-content {
    grid-column: 1 / span 5;
    grid-row: 3 / 4;
    padding-bottom: 14px;
    margin-bottom: 10px;
    border-bottom: solid #dadada 1px;
  }

  .actions-component {
    grid-column: 3 / span 5;
    grid-row: 4 / 5;
    justify-self: end;
  }
}

@media only screen and (min-width: 768px) {
  .card-container {
    .main-content {
      grid-column: 1 / 2;
      grid-row: 1 / span 5;
      margin-bottom: 0;
    }
    
    .primary-support-content {
      grid-column: 2 / 3;
      grid-row: 1 / span 5;
      padding-left: 14px;
      margin-bottom: 0;
      border-left: solid #dadada 1px;
    }
    
    .secondary-support-content {
      grid-column: 3 / 4;
      grid-row: 1 / span 5;
      padding-left: 14px;
      padding-bottom: 0;
      margin-bottom: 0;
      border-left: solid #dadada 1px;
      border-bottom: none;
    }

    .actions-component {
      grid-column: 4 / 5;
      grid-row: 1 / span 5;
      justify-self: end;
      align-self: start;
    }
  }
}

.card-container:last-child {
  margin-bottom: 0;
}

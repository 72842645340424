.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.d-flex {
  display: flex;
}

.bg-section-customer-detail {
  padding: 15px;
  padding-bottom: 64px;
  box-shadow: 0px 3px 6px #00000029;
}

.topic-credit-note {
  font-size: 18px;
  color: #1b4171;
  font-weight: 500;
}

.section-box-first {
  display: flex;
  align-items: center;
}

.text-nation {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  padding-right: 30px;
}

.section-radio-1 {
  display: flex;
  align-items: center;
  padding-right: 30px;

  p {
    padding-left: 8px;
  }
}

.text-legal-type {
  font-size: 16px;
}

.dot-legal {
  color: red;
  padding-left: 4px;
}

.legal-tax-input {
  width: 50px;
  height: 50px;
  outline: none;
  border: 1px solid #222222;
  text-align: center;
  margin-right: 10px;
}

.section-taxid-custom {
  display: flex;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
}

.legal-input {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  border: 1px solid #222222;
}

.legal-input-2 {
  width: 100%;
  height: 70px;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  border: 1px solid #222222;
}

.tax-id-input {
  width: 100%;
}

.section-register-address {
  padding: 15px;
  padding-bottom: 32px;
  box-shadow: 0px 3px 6px #00000029;
  border-bottom: 1px solid #00000029;

  .head-topic-text {
    font-size: 16px;
    font-weight: 500;
  }

  .text-topic-register-address {
    font-size: 14px;
    font-weight: 500;
  }
}

.section-refund-main {
  padding-left: 35px;
  padding-right: 35px;
}

.btn-submit-credit-note-1 {
  color: #fff;
  background-color: #1b4170;
}

.section-btn-create-credit-note {
  display: flex;
  justify-content: end;
  padding: 50px;
}

.text-topic-id-custom-create {
  font-size: 16px;
  font-weight: 500;
  color: #000000cc;
}

.section-name-branch {
  .legal-input {
    width: 100%;
    margin-left: 15px;
  }
}

.text-detail-legal {
  font-size: 18px;
  font-weight: 500;
  color: #000000cc;
}

.text-legal-type {
  font-size: 14px;
  font-weight: 500;
  color: #000000cc;
}

.input-select-custom-contact-name {
  position: relative;
  height: 32px;
  border: 1px solid #cccccc;
  outline: none;
  background-color: #ffffff;
}

.custom-select-create {
  height: 50px;

  .create-custom-2 {
    background-color: transparent;
  }
}

.overflow-x-md {
  overflow-x: scroll;
}

@media screen and(min-width: 768px) {
  .section-name-branch {
    .legal-input {
      width: 350px;
    }
  }

  .overflow-x-md {
    overflow-x: unset;
  }

  .tax-id-input {
    width: 60%;
  }
}

@media screen and(min-width: 1024px) {
  .tax-id-input {
    width: 40.5%;
  }

  .contact-name-bussiness {
    width: 460px;
  }
}

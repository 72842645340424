.payment-status-tag {
  display: inline-block;
  padding: 1px 8px;
  border-radius: 4px;
  background-color: #c8c8c8;
  color: white;
  text-align: center;

  &.false {
    background-color: #f44336;
  }

  &.true {
    background-color: #21ba45;
  }
}

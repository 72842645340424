.gender-choice {
  width: 48%;

  &:first-of-type {
    margin-right: 4%;
  }

  > button.ui.button.basic {
    &.basic {
      box-shadow: none;
      background-color: #f8f8f8 !important;
  
      &:hover {
        background-color: #f5f5f5 !important;
      }
    }

    &.active {
      background-color: #f1f1f1 !important;

      &.male {
        color: #1e9ac0 !important;
        font-weight: bold;
        box-shadow: 0 0 0 1px #1e9ac048 inset;
      }

      &.female {
        color: #ee62b4 !important;
        font-weight: bold;
        box-shadow: 0 0 0 1px #ee62b448 inset;
      }
    }
  }
}

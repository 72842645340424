.m-0 {
  margin: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-3 {
  margin-top: 1.5rem;
}

.p-0 {
  padding: 0px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.px-2 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.input-search {
  border: 1px solid;
  padding: 7px 14px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-color: #e0e0e0;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  height: 51px;
}

.btn-1 {
  height: 51px;
  width: 175px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.btn-search {
  background-color: #1b4170;
  border: 1px solid transparent;
  color: #fff;
  margin: 0px 15px 0px 0px;
}

.btn-filter-credit-note {
  background-color: #78a6dd4a;
  border: 1px solid #5b8ac3;
  color: #1b4170;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-filter-credit-note p {
  padding-left: 10px;
}

.btn-search:hover {
  opacity: 0.9;
}

.btn-filter-credit-note:hover {
  opacity: 0.9;
}

.section-select-pdf-and-date {
  display: flex;
  align-items: center;
}

.section-btn-search-and-filter {
  display: flex;
  align-items: center;
}

.btn-pdf-list-credit-note {
  background-color: #2d95f436;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 160px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 15px;
  color: #2d95f4;
  p {
    padding-left: 5px;
  }
}

.text-report-list-pdf {
  color: #2d95f4;
  font-size: 16px;
  padding-left: 16px;
}

.btn-select-date-credit-note {
  border: 1px solid #4183c4;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 95px;
  margin: 0px 15px;
  border-radius: 3px;
  font-size: 14px;
  color: #1b4171;
}

.section-creditnote-select-value {
  padding: 0px 15px;
  color: #000000cc;
}

// -------------- Calenda ----------------

.section-carenda-custom {
  padding-top: 16px;
}

.section-carenda-custom .rmdp-wrapper {
  width: 100%;
}

.rmdp-shadow {
  border: 1px solid #cbcbcb;
  box-shadow: 0 2px 3px 0 #00000029 !important;
}

.section-carenda-custom .rmdp-top-class {
  display: unset !important;
}

.section-carenda-custom .rmdp-day-picker {
  display: unset !important;
}

.today-and-dot {
  padding: 0px 15px;
  display: flex;
  padding-bottom: 15px;
}

.dot {
  margin-top: 3px;
  margin-right: 3px;
  width: 7px;
  height: 7px;
  background: red;
  border-radius: 50%;
}

.today-text {
  font-size: 13px;
}

.multi-locale-days .rmdp-day {
  width: 40px;
  height: 40px;
}

.custom-today {
  position: relative;
}

.rmdp-day {
  position: relative;
}

.rmdp-calendar {
  padding: 8px !important;
}

.rmdp-week-day {
  color: #47525d !important;
  font-size: 15px !important;
  font-weight: unset !important;
}

.rmdp-arrow {
  height: 12px !important;
  width: 12px !important;
}

.rmdp-arrow-container {
  margin: 0px 15px !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #0074d9 !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: unset !important;
  box-shadow: unset !important;
}

.rmdp-day.rmdp-deactive {
  color: #929598 !important;
}

.rmdp-day.rmdp-disabled {
  color: #e5e5e5 !important;
}

.rmdp-day.rmdp-today span {
  background-color: unset !important;
  color: unset !important;
  box-shadow: unset !important;
}

.rmdp-selected .custom-today .text-today {
  color: #ffffff !important;
}

.rmdp-selected span:not(.highlight) {
  background-color: #2d95f4 !important;
  box-shadow: unset !important;
  color: #ffffff !important;
  border-radius: 50%;
}

.custom-old-day {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cbcbcb;
  border-radius: 50%;
  background-color: #cbcbcb;
  color: #ffffff;
  outline: none;
}

.rmdp-day span:hover {
  background-color: #2d95f4 !important;
  color: #ffffff !important;
}

.rmdp-day.rmdp-disabled:hover span {
  background-color: unset !important;
  color: unset !important;
  box-shadow: unset !important;
}

.section-dot-and-button-credit-note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 15px 15px;
}

.section-btn-clear-and-apply {
  display: flex;
  align-items: center;
}

.btn-calendar-creditnote {
  width: 65px;
  padding: 5px 15px;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 500;
}

.btn-clear {
  background-color: #cccccc;
  color: #ffffff;
  margin-right: 10px;
}

.btn-apply {
  background-color: #1b4171;
  color: #ffffff;
}

.custom-padding-creditnote-calendar {
  padding: 0px;
}

// -------------- Table ----------------

.section-table-credit-note-custom {
  width: 100%;
  overflow-y: scroll;

  thead {
    background-color: rgba(120, 166, 221, 0.1);
    color: rgba(27, 66, 112, 1);
    text-align: left;

    tr th {
      border: none;
      padding: 20px;
      border-top: 1px solid rgba(126, 126, 126, 0.35);
      border-bottom: 1px solid rgba(126, 126, 126, 0.35);
    }
  }

  tbody {
    tr td {
      color: #2d95f4;
      padding: 20px;
      font-size: 16px;
      border-bottom: 1px solid rgba(126, 126, 126, 0.35);

      .under-line {
        text-decoration: underline;
      }

      .text-job {
        width: 120px;
      }

      .total {
        font-size: 16px;
        font-weight: 700;
        color: #222222;
      }

      .time {
        min-width: 200px;
        font-size: 15px;
        font-weight: 400;
        color: #222222;
      }

      .default-status {
        border: 1px solid;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0px;
        font-weight: 500;
        text-transform: capitalize;
        width: 200px;
      }

      .status-voided {
        color: rgba(232, 53, 40, 1);
        border-color: rgba(232, 53, 40, 1);
        background-color: rgba(232, 53, 40, 0.1);
      }

      .status-completed {
        color: rgba(0, 186, 50, 1);
        border-color: rgba(0, 186, 50, 1);
        background-color: rgba(0, 186, 50, 0.1);
      }

      .status-refund {
        color: rgba(255, 224, 92, 1);
        border-color: rgba(255, 224, 92, 1);
        background-color: rgba(255, 224, 92, 0.1);
      }

      .status-pending_refund {
        border-color: #f5b300;
        background-color: #f5b30031;
        color: #f5b300;
      }
    }
  }
}

.select-input-custom-credit-note {
  text-transform: capitalize;
}

.text-list-table-credit-note {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
}

// ------------- Modal ---------------

.inside-modal-filter-list-credit-note {
  width: 450px;
  height: 500px;
}

@media screen and(min-width: 1280px) {
  .section-btn-search-and-filter {
    display: flex;
    align-items: center;
  }

  .btn-search {
    margin: 0px 25px 0px 0px;
  }
}

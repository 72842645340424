.boolean-tag {
  display: inline-block;
  padding: 1px 8px;
  border-radius: 4px;
  color: white;
  min-width: 41px;
  text-align: center;

  &.true {
    background-color: #21ba45;
  }

  &.false {
    background-color: #f44336;
  }
}

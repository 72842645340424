.code-tag {
  display: inline-block;
  padding: 1px 8px;
  border-radius: 4px;
  background-color: black;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.btn-custom-new-arrary {
  border: 2px solid #1b4171;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  transition: width 0.5s;
  width: 140px;
}

.btn-custom-reasons {
  font-size: 13px;
  color: #2d95f4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text-btn-reasons {
  padding-left: 5px;
  color: #2d95f4;
}

.text-btn-custom {
  padding-left: 10px;
  font-size: 16px;
  color: #1b4171;
  font-weight: 500;
}

.btn-custom-new-arrary:hover {
  background-color: #1b4171;
  width: 160px;
}

.btn-custom-new-arrary:hover .svg-icon {
  fill: #ffffff;
}

.btn-custom-new-arrary:hover .text-btn-custom {
  color: #ffffff;
}

.custom-section-choices {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
}

.custom-section-choices .icon-delete-choice {
  position: absolute;
  right: 15px;
  padding: 0px;
}

.input-field-choices-custom {
  width: 100%;
  border: 1px solid;
  padding: 14px 50px 14px 14px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-color: #e0e0e0;
  border-radius: 4px;
  background-color: white;
  outline: none;
}

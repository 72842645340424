.user-property.ui.grid {
  > div.row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    > div.column:first-child {
      padding-right: 0;
    }
  }
}

.btn-switch-custom-time-job {
  cursor: pointer;
  border: 1px solid #1b4171;
  width: 200px;
  font-size: 13px;
  border-radius: 15px;
  padding: 5px 10px;
}

.btn-switch-custom-time-job:hover {
  background-color: #1b4171;
  color: #ffffff;
}

.active-btn-switch {
  background-color: #1b4171;
  color: #ffffff;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.input-reschedule-job {
  padding: 15px;
  height: 50px;
  width: 100%;
}

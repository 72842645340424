.invoice-fontMedium {
  font-size: 18px;
  font-weight: 500;
}

.invoice-fontSmall {
  color: #4b4b4b;
  font-size: 16px;
  font-weight: 500;
}

.content-title-part {
  display: flex;
  align-items: center;
}

.expand-part {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

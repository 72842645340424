.page {
  background: linear-gradient(270deg, #466B95 0%, #193F71 100%);
  height: 100vh;

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;

    .info-section {
      display: none;

      @media screen and (min-width: 768px) {
        flex-basis: 50%;
        min-height: 40%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        text-align: center;
      }

      .app-logo {
        width: 35%;
        margin-bottom: 50px;
      }

      .description {
        width: 80%;
        margin: 0 auto;
      }
    }
  
    .form-section {
      flex-basis: 100%;
      min-height: 55%;
      padding: 24px;
      margin: auto 16px;
      background-color: white;
      text-align: center;

      .tenant-logo {
        max-height: 150px;
        max-width: 100%;
        width: auto;
        margin: 0 auto;
      }

      .welcome-message {
        margin: 50px 0 30px;
      }

      .divider {
        margin: 37px 0 23px;
        
        > div {
          display: inline-block;
          width: 42%;
          border-bottom: 1px solid #ddd;
          margin-bottom: 4px;
        }

        > label {
          display: inline-block;
          width: 16%;
          text-align: center;
        }
      }

      @media screen and (min-width: 768px) {
        box-shadow: 2px 2px 15px 0 #193F71;
        padding: 24px 100px;
        margin: auto 0;
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
      }
    }
  }
}
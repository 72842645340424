.alert-modal {
  font-size: 16px;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #47525d;
    margin-bottom: 24px;
  }

  .action {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 16px;

      &.cancel-btn {
        color: #8e8e93;
      }
    }
  }
}

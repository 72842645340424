.comment-card-contanier  {
  border-radius: 5px;
  border: 1px solid #989aa2;
  margin-bottom: 8px;

  .position-title {
    position: absolute;
    right: 8px;
    color: #2D95F4;
  }

  .name-title {
    font-size: 20px;
    font-weight: 500;
  }

  .time-comment {
    font-size: 16px;
    font-weight: 400;
    color: #1B4171;
  }
}
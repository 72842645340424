.tag {
  font-weight: bold;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 15px;
  background-color: #eee;
  padding: 4px 8px;
  margin-right: 3px;

  &.primary {
    background-color: #1b4170;
    color: white;
  }

  // Genders

  &.male {
    background-color: #d8f2fd;
  }

  &.female {
    background-color: #fce8e8;
  }

  // Provider States

  &.registering {
    background-color: #e5e5e5;
  }

  &.pending_approval {
    background-color: #ffd300;
  }

  &.approved {
    background-color: #18cb88;
    color: white;
  }

  &.suspened {
    background-color: #ff8941;
    color: white;
  }

  &.banned {
    background-color: #f5474f;
    color: white;
  }

  &.providers,
  &.customers {
    background-color: #1b4170;
    color: white;
  }

  &.LINE {
    background-color: #00c300;
    color: white;
  }

  &.bank-account-states,
  &.company-states,
  &.job-states,
  &.inquiry-states,
  &.job-provider-states {
    &.pending,
    &.pending_payment,
    &.pending_start,
    &.pending_approval {
      background-color: #ff8941;
      color: white;
    }

    &.pending_provider {
      background-color: #1b4170;
      color: white;
    }

    &.approved,
    &.completed {
      background-color: #18cb88;
      color: white;
    }

    &.pending_completion {
      background-color: #18abcc;
      color: white;
    }

    &.cancelled {
      background-color: #f5474f;
      color: white;
    }

    &.expired,
    &.rejected {
      background-color: #999999;
      color: white;
    }

    &.rescheduling {
      background-color: #fddd26;
    }
  }

  &.wallet-transaction-states,
  &.invoice-states,
  &.line-item-states,
  &.payment-states {
    &.pending,
    &.holding {
      background-color: #ff8941;
      color: white;
    }

    &.expected,
    &.processing {
      background-color: #2d95f4;
      color: white;
    }

    &.captured,
    &.paid,
    &.completed,
    &.processed {
      background-color: #18cb88;
      color: white;
    }

    &.cancelled {
      background-color: #f5474f;
      color: white;
    }

    &.voided,
    &.expired {
      background-color: #999999;
      color: white;
    }
  }
}

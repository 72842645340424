.drag-and-drop-file-border {
  position: relative;
  border: 1px dashed #8f9ba9;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0px;
  background-color: #f0f0f0;

  .text-drop {
    font-size: 16px;
    padding-top: 16px;
    color: #000000cc;
  }

  .section-box-btn {
    padding-top: 16px;
    display: flex;
    justify-content: center;
  }

  .btn-browse-upload-file {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 25px;
    color: #ffffff;
    font-weight: 500;
    background-color: #1b4170;
    border-radius: 4px;
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.drag-and-drop-file-border:hover,
.drag-over {
  opacity: 0.6;
}

.section-box-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.link-custom-new-tab-upload-file {
  color: #222222;
}

.box-text-file {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
  padding-right: 1rem;
}

.topic-upload-file {
  font-size: 16px;
  font-weight: 500;
  color: #000000cc;
}

.text-example-upload-file {
  font-size: 14px;
  color: #000000cc;
}

.section-input-upload-file {
  display: flex;
  align-items: center;
  width: 400px;
  svg {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
}

.input-upload-file-transaction {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  height: 40px;
  padding-right: 50px;
  padding-left: 10px;
  border-radius: 4px;
  border: 2px solid #8f9ba9;
}

.input-upload-file-transaction:focus {
  outline: none;
  border: 2px solid #2d95f4;
}

.text-errors-upload-file {
  padding-left: 10px;
  color: red;
}

.icon-remove-upload-file,
.text-view-more-custom-upload-file {
  padding-right: 10px;
}

.text-view-more-custom-upload-file {
  display: flex;
  align-items: center;
  justify-content: end;
  color: #2d95f4;
  text-decoration: underline;
}

@media screen and(min-width: 768px) {
  .section-box-file-upload {
    justify-content: unset;
  }
}

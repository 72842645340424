.datetime-input {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  line-height: 34px;
  padding: 0 10px;

  &:focus {
    border-width: 1px;
  }
}

.job-card-container {
  display: grid;
  grid-template-columns: 50% 35% 15%;
  grid-template-rows: auto auto auto 20px;
  position: relative;
  background: #fff;
  margin: 1rem 0;
  padding: 16px 22px;
  border: 1px solid rgba(34, 36, 38, 0.11);
  font-size: 1rem;
  
  .main-content {
    grid-column: 1 / span 5;
    grid-row: 1 / 2;
    margin-bottom: 14px;
    padding-top: 8px;
    
    > a > img {
      border-radius: 50%;
      height: 60px;
      width: 60px;
      margin-right: 10px;
      vertical-align: middle;
    }
    
    > span {
      display: inline-block;
      position: absolute;
      // padding-top: 14px;
      
      .title {
        padding-left: 16px;
        padding-top: 2px;
        font-size: 13px;
        color: #222222;
      }
    }
  }
  
  .primary-support-content {
    grid-column: 1 / span 5;
    grid-row: 2 / 3;
    margin-bottom: 14px;
  }
  
  .secondary-support-content {
    grid-column: 1 / span 5;
    grid-row: 3 / 4;
    padding-bottom: 14px;
    margin-bottom: 10px;
    // border-bottom: solid #dadada 1px;
  }

  .actions-component {
    grid-column: 3 / span 5;
    grid-row: 4 / 5;
    justify-self: end;
  }
}

@media only screen and (min-width: 768px) {
  .job-card-container {
    .main-content {
      grid-column: 1 / 2;
      grid-row: 1 / span 5;
      margin-bottom: 0;
    }
    
    .primary-support-content {
      grid-column: 2 / 3;
      grid-row: 1 / span 5;
      padding-left: 14px;
      margin-bottom: 0;
      // border-left: solid #dadada 1px;
    }
    
    // .secondary-support-content {
    //   grid-column: 3 / 4;
    //   grid-row: 1 / span 5;
    //   padding-left: 14px;
    //   padding-bottom: 0;
    //   margin-bottom: 0;
    //   // border-left: solid #dadada 1px;
    //   border-bottom: none;
    // }

    .actions-component {
      grid-column: 3 / 4;
      grid-row: 1 / span 5;
      justify-self: end;
      align-self: start;
    }
  }
}

.card-container:last-child {
  margin-bottom: 0;
}

.org-container {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  color: #4183c4;
  font-weight: 500;
  .org-avatar {
    flex: 0.2;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .name {
    flex: 1;
  }
  .phone-number {
    flex: 1;
    display: flex;
    .icon {
      margin-right: 4px !important;
    }
  }
}

.org-card-container {
  display: flex;
  align-items: center;
  background: #fff;
  margin: 1rem 0;
  padding: 16px;
  border: 1px solid rgba(34, 36, 38, 0.11);
  font-size: 1rem;

  .main-content {
    > a > img {
      border-radius: 50%;
      height: 60px;
      width: 60px;
      margin-right: 10px;
      vertical-align: middle;
    }
    
    > span {
      display: inline-block;
      position: absolute;
      
      .title {
        padding-left: 16px;
        padding-top: 2px;
        font-size: 13px;
        color: #222222;
      }
    }
  }
  
  .primary-support-content {
    grid-column: 1 / span 5;
    grid-row: 2 / 3;
    margin-bottom: 14px;
  }
  
  .secondary-support-content {
    grid-column: 1 / span 5;
    grid-row: 3 / 4;
    padding-bottom: 14px;
    margin-bottom: 10px;
  }

  .actions-component {
    grid-column: 3 / span 5;
    grid-row: 4 / 5;
    justify-self: end;
  }
}

#service-setting-pane {
  .text-medium {
    font-size: 18px;
    font-weight: 500;
  }

  .subtext-small {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
  }

  .content-title-part {
    display: flex;
    align-items: center;
  }

  .expand-part {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

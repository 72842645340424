.google-map-container {
  position: relative;
  width: 100%;
  height: 400px;

  .google-map {
    width: 100%;
    height: 100%;
  }

  .google-map-marker {
    position: absolute;
    // width: 100%;
    height: 40px;
    top: -40px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #f44336;
  }

  .google-map-places-container {
    position: absolute;
    padding: 10px;
    width: 100%;

    &.hidden {
      display: none;
    }

    .google-map-places-input {
      width: 100%;
      font-size: 1rem;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc;

      &:focus, &:hover {
        border: 1px solid #5b8ac3;
        outline: none;
      }
    }
  }
}

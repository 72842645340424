.section-btn-slot-job {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #46535d;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px;
}

.inside-time-slot-text {
  font-size: 16px;
  font-weight: 500;
  margin: 3px;
  text-align: center;
}

.section-btn-slot-job:hover {
  background-color: #46535d;
  color: #ffffff;

  .low-remain {
    color: #ffffff;
  }

  .normal-remain {
    color: #ffffff;
  }
}

.active-slot-btn-job {
  background-color: #46535d;
  color: #ffffff;

  .low-remain {
    color: #ffffff;
  }

  .normal-remain {
    color: #ffffff;
  }
}

.disable-slot-btn-job {
  cursor: not-allowed;
  color: #8f9ba9;
  background-color: #cccccc;
  border: 2px solid transparent;
}

.disable-slot-btn-job:hover {
  background-color: #cccccc;
  color: #8f9ba9;
}

.low-remain {
  color: #d0021b;
}

.normal-remain {
  color: #2d95f4;
}

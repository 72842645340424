.drag-handle {
  cursor: grab;
}

.category-expanded {
  transform: rotate(180deg);
}

.category-card-root {
  margin-bottom: 10px;
}

.category-cardContent {
  display: flex;
}

.category-title-container {
  width: 100%;
}

.category-title-container {
  .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }
}

.section-money-refund-and-time {
  min-height: 150px;
  padding: 30px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-money-1 {
  color: #222222;
  font-weight: 500;
}

.text-time-1 {
  color: #222222;
}

.input-select-custom-creditnote-contact-name {
  position: relative;
  height: 50px;
  border: 1px solid #cccccc;
  outline: none;
  background-color: #ffffff;
}

.section-back-to-previous {
  align-items: center;
  display: flex;
  color: #2d95f4;
  font-size: 18px;

  .icon-left {
    font-size: 32px;
  }
}

.card-status-creditnite {
  border: 1px solid;
  border-radius: 15px;
  padding: 3px 15px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-completed-creditnote {
  border-color: #00ba3231;
  background-color: #00ba3231;
  color: #00ba32;
}

.status-voided-creditnote {
  border-color: #e8352849;
  background-color: #e8352849;
  color: #e83528;
}

.status-pending_refund-creditnote {
  border-color: #f5b300;
  background-color: #f5b30031;
  color: #f5b300;
}

.section-id-tax-and-status {
  display: flex;
  align-items: center;
}

.number-account {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.icon-bank-custom-creditnote {
  width: 40px;
  height: 40px;
  border: 1px solid #7e7e7e;
  border-radius: 50%;
}

.custom-color-bank-detail {
  color: #7e7e7e;
  font-weight: 400 !important;
}

.id-text-show {
  font-size: 32px;
  color: #222222;
  font-weight: 500;
  padding-right: 10px;
}

.section-top-btn-and-id {
  padding: 20px;
  min-height: 150px;
}

.btn-download-pdf {
  display: flex;
  align-items: center;
  background-color: #1b4171;
  margin-right: 15px;
  p {
    padding-left: 5px;
  }
}

.btn-voide-creditnote {
  background-color: red;
}

.btn-credit-note-1 {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
}

.section-2-btn {
  padding-top: 16px;
}

.box-disabled-custom-id {
  background-color: rgba(239, 239, 239, 0.3);

  .font-fee {
    color: rgb(84, 84, 84);
  }
}

@media screen and(min-width: 600px) {
  .section-top-btn-and-id {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
  }

  .section-2-btn {
    padding-top: 0px;
    display: flex;
    align-items: center;
  }

  .btn-credit-note-1 {
    margin-bottom: 0rem;
  }
}

@media screen and(min-width: 768px) {
  .text-money-1 {
    font-size: 24px;
  }

  .text-time-1 {
    font-size: 19px;
  }
}
